import { createAction, createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { AppState } from "./store";
import { HYDRATE } from "next-redux-wrapper";
import { postEditFormsReducers, PostFormState } from "./postFormSlice";

const hydrate = createAction<AppState>(HYDRATE);

export interface EditFormState extends PostFormState {}

// Initial state
const initialState: EditFormState = {
    coordinates: [],
    selectedDriveUpdated: false,
    selectedDriveValid: false,
    mileageUpdated: false,
    mileageValid: false,
    autoFilled: false,
    characteristicsFilled: false,
    photoFilled: false,
    descriptionFilled: false,
    contactsFilled: false,
    placeFilled: false,
    priceFilled: false,
    isFilled: false,
    selectedPics: [],
    selectedSellerType: "individual",
    selectedYearUpdated: false,
    selectedYearValid: false,

    selectedBodyTypeUpdated: false,
    selectedBodyTypeValid: false
};

export const editFormSlice = createSlice({
    name: "editForm",
    initialState,
    reducers: postEditFormsReducers,

    extraReducers: (builder) => {
        builder.addCase(hydrate, (state, action) => {
            return {
                ...state,
                ...action.payload.postForm
            };
        });
    }
});

export const {
    updateStatus,
    setBrand,
    setModel,
    setGeneration,
    setSelectedEngine,
    setSelectedDrive,
    setBodyType,
    setYear,
    setSelectedColor,
    setSelectedDescription,
    setSelectedPrice,
    setSelectedCity,
    setSelectedCountry,
    setSelectedPhone,
    setSelectedName,
    setSelectedSellerType,
    setSelectedBodyType,
    setSelectedMileage,
    setSelectedStreet,
    setSelectedPics,
    setSelectedOwnersQnt,
    setSelectedTransmission,
    setSelectedWheel,
    loadDefaultValues,
    reset,
    setHorsePower,
    setVinNumber,
    setCoordinates
} = editFormSlice.actions;

export const selectFilledStatus = (state: AppState) => state.editForm;

export const actions = editFormSlice.actions;

export default editFormSlice.reducer;