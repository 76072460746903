import {
    BaseQueryApi,
    BaseQueryFn,
    FetchArgs,
    fetchBaseQuery,
    FetchBaseQueryError
} from "@reduxjs/toolkit/dist/query";
import {
    BaseQueryExtraOptions,
    QueryReturnValue
} from "@reduxjs/toolkit/dist/query/baseQueryTypes";
import { loginUser, refreshToken } from "./authSlice";
import { showEnterForm } from "./layoutSlice";
import { MaybePromise } from "@reduxjs/toolkit/dist/query/tsHelpers";
import { addIntension, Intension } from "./intensionsSlice";
export const baseQuery = fetchBaseQuery({
    timeout: 10000
});
export declare type BaseQueryFnWithForm<
    Args = any,
    Result = unknown,
    Error = unknown,
    DefinitionExtraOptions = {},
    Meta = {}
> = (
    args: Args,
    api: BaseQueryApi,
    extraOptions: DefinitionExtraOptions,
    showForm?: boolean,
    intension?: Intension
) => MaybePromise<QueryReturnValue<Result, Error, Meta>>;

/**
 * Базовый запрос с обработкой ошибки 401
 *
 * @param showForm - показывать форму входа при ошибке 401
 *
 * @example
 * const result: any = await baseQueryWithReauth(
 *                   {
 *                      headers: {
 *                         "Content-Type": "application/json",
 *                        "X-Auth-Token": TOKEN,
 *                   },
 *                  url: `${API}/v1/favorites`,
 *            },
 *            api,
 *           extraOptions,
 *          false // не показывать форму входа при ошибке 401
 *     );
 *
 */
export const baseQueryWithReauth: BaseQueryFnWithForm<
    string | FetchArgs,
    unknown,
    FetchBaseQueryError
> = async (args, api, extraOptions, showForm = true, intension?: Intension) => {
    let result = await baseQuery(args, api, extraOptions);

    if (result.error && result.error.status === 401) {
        if (intension) {
            api.dispatch(addIntension(intension));
        }
        const refreshData = await api.dispatch(refreshToken({}));

        if (refreshData.type === "auth/refreshToken/rejected" && showForm) {
            api.dispatch(showEnterForm());
        }
    }
    return result;
};