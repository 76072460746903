import { usePathname } from "next/navigation";
import { Router, useRouter } from "next/router";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { User } from "../../../interfaces/user.interface";
import { AppState } from "../../../app/store/store";
import {
    getAccessToken,
    getUserData,
    setReduxAccessToken,
    setRouteToOpen,
    setUser
} from "../../../app/store/authSlice";
import { showEnterForm } from "../../../app/store/layoutSlice";

export const privateRoutes = ["/profile", "/my-ads", "/saved", "/history", "/post"];

export const isPrivateRoute = (url: string) => {
    const isPrivateRoute = privateRoutes.some((route) => {
        if (route.includes(url)) {
            return true;
        }
    });

    const isPrivateRouteOrMain = isPrivateRoute && url !== "/";

    return isPrivateRouteOrMain;
};

export const AuthCheck = (props: { children: JSX.Element }) => {
    const dispatch = useDispatch();
    const router = useRouter();
    const user = useSelector((state: AppState) => state.auth.currentUser);
    const pathname = router.pathname; //usePathname();

    useEffect(() => {
        let currentUser: User | null;
        if (typeof window !== "undefined") {
            currentUser = getUserData() as User | null;

            if (currentUser) {
                dispatch(setUser(currentUser));
            }

            const token = getAccessToken();
            dispatch(setReduxAccessToken(token));
        } else {
            currentUser = null;
        }
    }, []);

    useEffect(() => {
        // Router changes
        const start = (url: string, params: any) => {
            if (isPrivateRoute(url) && !user) {
                dispatch(showEnterForm());
                throw "Abort route change by user's confirmation.";
            }
        };
        router.events.on("routeChangeStart", start);

        // If the component is unmounted, unsubscribe
        // from the event with the `off` method:
        return () => {
            router.events.off("routeChangeStart", start);
        };
    }, [user]);

    useEffect(() => {
        const currentUser = getUserData() as User | null;

        // General loading
        if (isPrivateRoute(pathname || "") && !currentUser) {
            if (pathname) {
                dispatch(setRouteToOpen(pathname));
            }

            dispatch(showEnterForm());
            // router.push('/').then(() => {

            // })
        }
    }, []);
    return props.children;
};